
import { Component, Vue } from 'vue-property-decorator';
import EmployeejoincontentMain from "@/components/employeejoincontent/Main.vue";

@Component({
  components: {
    EmployeejoincontentMain,
  }
})
export default class EmployeeEach extends Vue {

}
