
import { Component, Mixins, Vue, Prop } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import EmployeeeachSelfFamilySpouceIsspouce from "@/components/employeeeach/self/family/spouce/Isspouce.vue";
import EmployeeeachSelfFamilySpouceIsspouceeither from "@/components/employeeeach/self/family/spouce/Isspouceeither.vue";
import EmployeeeachSelfFamilySpouceName from "@/components/employeeeach/self/family/spouce/Name.vue";
import EmployeeeachSelfFamilySpouceBirthdate from "@/components/employeeeach/self/family/spouce/Birthdate.vue";
import EmployeeeachSelfFamilySpouceMynum from "@/components/employeeeach/self/family/spouce/Mynum.vue";
import EmployeeeachSelfFamilySpouceHousemate from "@/components/employeeeach/self/family/spouce/Housemate.vue";
import EmployeeeachSelfFamilySpouceAddress from "@/components/employeeeach/self/family/spouce/Address.vue";
import EmployeeeachSelfFamilySpouceNotresident from "@/components/employeeeach/self/family/spouce/Notresident.vue";
import EmployeeeachSelfFamilySpouceRemittance from "@/components/employeeeach/self/family/spouce/Remittance.vue";
import EmployeeeachSelfFamilySpouceIncome from "@/components/employeeeach/self/family/spouce/Income.vue";
import EmployeeeachSelfFamilySpouceDisability from "@/components/employeeeach/self/family/spouce/Disability.vue";
import EmployeeeachSelfFamilySpouceDisabilityreason from "@/components/employeeeach/self/family/spouce/Disabilityreason.vue";
import EmployeeeachSelfFamilySpouceHousematelivelihood from "@/components/employeeeach/self/family/spouce/Housematelivelihood.vue";

@Component({
  components: {
    EmployeeeachSelfFamilySpouceIsspouce,
    EmployeeeachSelfFamilySpouceIsspouceeither,
    EmployeeeachSelfFamilySpouceName,
    EmployeeeachSelfFamilySpouceBirthdate,
    EmployeeeachSelfFamilySpouceMynum,
    EmployeeeachSelfFamilySpouceHousemate,
    EmployeeeachSelfFamilySpouceAddress,
    EmployeeeachSelfFamilySpouceNotresident,
    EmployeeeachSelfFamilySpouceRemittance,
    EmployeeeachSelfFamilySpouceIncome,
    EmployeeeachSelfFamilySpouceDisability,
    EmployeeeachSelfFamilySpouceDisabilityreason,
    EmployeeeachSelfFamilySpouceHousematelivelihood,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() old_employee: any;
  @Prop() spouce: any;
  @Prop() old_spouce: any;
}
