
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import { Provide } from '@/library/provide';
import LDialog from "@/components/l/Dialog.vue";
import LContent from "@/components/l/Content.vue";
import LBread from "@/components/l/Bread.vue";
import EmployeejoincontentDenyReason from "@/components/employeejoincontent/deny/Reason.vue";

@Component({
  components: {
    LDialog,
    LContent,
    LBread,
    EmployeejoincontentDenyReason,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;

  get is_display() {
    return !!this.manager.flag;
  }
  set is_display(val: boolean) {
    if (!val) this.manager.cancel();
  }

  get raw_object() {
    return {name: this.employee.name ?? '', reason: null};
  }
  get tmp_object() {
    return this.manager.object.employee;
  }

  @Provide('is_edit')
  get is_edit() {
    return !!this.manager.flag;
  }
  @Provide('is_add')
  get is_add() {
    return !!this.manager.flag;
  }
}
