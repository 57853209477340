
import { Component, Prop, Mixins } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import EmployeeeachSelfTaxTaxtype from "@/components/employeeeach/self/tax/Taxtype.vue";
import EmployeeeachSelfTaxDependantnum from "@/components/employeeeach/self/tax/Dependantnum.vue";
import EmployeeeachSelfTaxHouseholdername from "@/components/employeeeach/self/tax/Householdername.vue";
import EmployeeeachSelfTaxHouseholderrelationship from "@/components/employeeeach/self/tax/Householderrelationship.vue";
import EmployeeeachSelfTaxIncome from "@/components/employeeeach/self/tax/income.vue";
import EmployeeeachSelfTaxDisability from "@/components/employeeeach/self/tax/Disability.vue";
import EmployeeeachSelfTaxDisabilityreason from "@/components/employeeeach/self/tax/Disabilityreason.vue";
import EmployeeeachSelfTaxSinglechild from "@/components/employeeeach/self/tax/Singlechild.vue";
import EmployeeeachSelfTaxWidow from "@/components/employeeeach/self/tax/Widow.vue";
import EmployeeeachSelfTaxWorkstudent from "@/components/employeeeach/self/tax/Workstudent.vue";
import EmployeeeachSelfTaxSchool from "@/components/employeeeach/self/tax/School.vue";

@Component({
  components: {
    EmployeeeachSelfTaxTaxtype,
    EmployeeeachSelfTaxDependantnum,
    EmployeeeachSelfTaxHouseholdername,
    EmployeeeachSelfTaxHouseholderrelationship,
    EmployeeeachSelfTaxIncome,
    EmployeeeachSelfTaxDisability,
    EmployeeeachSelfTaxDisabilityreason,
    EmployeeeachSelfTaxSinglechild,
    EmployeeeachSelfTaxWidow,
    EmployeeeachSelfTaxWorkstudent,
    EmployeeeachSelfTaxSchool,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() old_employee: any;
}
