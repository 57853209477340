
import { Component, Mixins, Watch } from 'vue-property-decorator';
import mixinEmployeeAdmit from '@/mixins/mixinEmployeeAdmit';
import { Provide } from '@/library/provide';
import LProgress from "@/components/l/Progress.vue";
import LPage from "@/components/l/Page.vue";
import LBread from "@/components/l/Bread.vue";
import LSidemenu from "@/components/l/Sidemenu.vue";
import FSidemenuscroll from "@/components/f/Sidemenuscroll.vue";
import LContent from "@/components/l/Content.vue";
import EmployeedemandadmitBase from "@/components/employeedemandadmit/Base.vue";
import EmployeedemandadmitAddress from "@/components/employeedemandadmit/Address.vue";
import EmployeedemandadmitBank from "@/components/employeedemandadmit/Bank.vue";
import EmployeedemandadmitTax from "@/components/employeedemandadmit/Tax.vue";
import EmployeedemandadmitSpouce from "@/components/employeedemandadmit/Spouce.vue";
import EmployeedemandadmitDependants from "@/components/employeedemandadmit/Dependants.vue";
import EmployeejoinEmployeeinfo from "@/components/employeejoin/Employeeinfo.vue";
import EmployeejoincontentDeny from "@/components/employeejoincontent/Deny.vue";
import FButton from "@/components/f/Button.vue";

@Component({
  components: {
    LProgress,
    LPage,
    LBread,
    LSidemenu,
    FSidemenuscroll,
    LContent,
    EmployeedemandadmitBase,
    EmployeedemandadmitAddress,
    EmployeedemandadmitBank,
    EmployeedemandadmitTax,
    EmployeedemandadmitSpouce,
    EmployeedemandadmitDependants,
    EmployeejoinEmployeeinfo,
    EmployeejoincontentDeny,
    FButton,
  }
})
export default class Main extends Mixins(mixinEmployeeAdmit) {
  created() {
    this.m.initEditManager({name: 'admit'});
    this.m.initEditManager({name: 'deny'});
    this.fetchedGlobal(this.fetched);
  }

  admit() {
    this.change_page('employee_admit', {employee_id: this.employee.id});
  }
  deny() {
    this.m.edit_manager.deny.edit({employee: {name: this.employee.name, reason: null}});
  }

  @Watch('fetched')
  fetchedGlobal(fetched) {
    if (fetched) {
      this.m.edit_manager.admit.edit({
        employee: this.employeely.employee,
        spouce: this.family.spouce,
        dependants: this.family.dependants,
        dependant_num: Object.keys(this.family.dependants).length,
        each_employee_infos: this.info.each_employee_infos,
      });
    }
  }


  get fetched() {
    return !!this.employeely && !!this.family && !!this.info;
  }
      
  get employee() {
    return this.m.edit_manager.admit.object.employee;
  }
  get dependants() {
    return this.m.edit_manager.admit.object.dependants;
  }
  get spouce() {
    return this.m.edit_manager.admit.object.spouce;
  }
  get dependant_num() {
    return this.m.edit_manager.admit.object.dependant_num;
  }


  @Provide('employee_type')
  get employee_type() {
    return this.employee.type;
  }

  @Provide('is_edit')
  get is_edit() {
    return false;
  }
  @Provide('is_add')
  get is_add() {
    return false;
  }
}
