
import { Component, Mixins, Prop } from 'vue-property-decorator';
import mixinPage from '@/mixins/mixinPage';
import EmployeeeachSelfFamilyDependantCount from "@/components/employeeeach/self/family/dependant/Count.vue";
import LItemlevel from "@/components/l/Itemlevel.vue";
import EmployeedemandadmitDependant from "@/components/employeedemandadmit/Dependant.vue";

@Component({
  components: {
    EmployeeeachSelfFamilyDependantCount,
    LItemlevel,
    EmployeedemandadmitDependant,
  }
})
export default class Main extends Mixins(mixinPage) {
  @Prop() employee: any;
  @Prop() old_employee: any;
  @Prop() dependants: any;
  @Prop() old_dependants: any;

  get dependant_num() {
    return this.manager.object.dependant_num;
  }
  set dependant_num(val) {
    this.manager.object.dependant_num = val;
  }
}
